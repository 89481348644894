export const actions = {
  ORG_MANAGE_START: 'ORG_MANAGE_START',
  ORG_MANAGE_END: 'ORG_MANAGE_END',
  ORG_EDIT_START: 'ORG_EDIT_START',
  ORG_EDIT_END: 'ORG_EDIT_END',
  ORG_MANAGE_MEMBERS_START: 'ORG_MANAGE_MEMBERS_START',
  ORG_MANAGE_MEMBERS_END: 'ORG_MANAGE_MEMBERS_END',
  ORG_MANAGE_MEMBERS_PENDING_START: 'ORG_MANAGE_MEMBERS_PENDING_START',
  ORG_MANAGE_MEMBERS_PENDING_END: 'ORG_MANAGE_MEMBERS_PENDING_END',
  ORG_RESERVE_LICENCES_START: 'ORG_RESERVE_LICENCES_START',
  ORG_RESERVE_LICENCES_END: 'ORG_RESERVE_LICENCES_END',
  ORG_FREEZE_LICENCES_START: 'ORG_FREEZE_LICENCES_START',
  ORG_FREEZE_LICENCES_END: 'ORG_FREEZE_LICENCES_END',
  ORG_MANAGE_EXTERNAL_MEMBERS_START: 'ORG_MANAGE_EXTERNAL_MEMBERS_START',
  ORG_MANAGE_EXTERNAL_MEMBERS_END: 'ORG_MANAGE_EXTERNAL_MEMBERS_START',
  ORG_MANAGE_AUTO_EXTEND_ID_RANGE_START: 'ORG_MANAGE_AUTO_EXTEND_ID_RANGE_START',
  ORG_MANAGE_AUTO_EXTEND_ID_RANGE_END: 'ORG_MANAGE_AUTO_EXTEND_ID_RANGE_END',
  ORG_MANAGE_ORG_DEFAULT_COMMUNITY_START: 'ORG_MANAGE_ORG_DEFAULT_COMMUNITY_START',
  ORG_MANAGE_ORG_DEFAULT_COMMUNITY_END: 'ORG_MANAGE_ORG_DEFAULT_COMMUNITY_END',
  ORG_MANAGE_UDF_START: 'ORG_MANAGE_UDF_START',
};

export default actions;
