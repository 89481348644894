/**
 * Organisation summary tools
 *
 * Provides functionality to manage and access organisation features.
 * Used on organisation summary screen.
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { organisationSummaryActionCreators } from '../OrganisationSummary.operations';
import { organisationActionCreators } from '../../../controllers/OrganisationController/Organisation.operations';

/**
 * Constants
 */
import ManagementToolsCommonComponent from '@govteams/library/components/ManageOrganisation/ManagementToolsComponent/';

import { tooltips } from '../OrganisationSummary.constants';
import { isProtected } from '../../../common/Utils';

const ManagementToolsComponent = (props) => {
  const { organisationId, isExporting, organisationSummaryActionCreators, organisationActionCreators } = props;
  const handleClickViewUserList = () => {
    organisationSummaryActionCreators.startOrganisationMembersManage(organisationId);
  };
  const handleClickExportUserList = () => {
    organisationActionCreators.exportOrganisationMemberCsv(organisationId);
  };
  const handleClickExternalMembers = () => {
    organisationSummaryActionCreators.startExternalMembersManage(organisationId)
  };
  const handleClickReserveLicense = () => {
    organisationSummaryActionCreators.startOrganisationMembersReserveLicences(organisationId)
  };
  const handleClickPendingLicense = () => {
    organisationSummaryActionCreators.startOrganisationMembersPendingManage(organisationId)
  };
  const handleClickBlockLicense = () => {
    organisationSummaryActionCreators.startOrganisationMembersBlockLicences(organisationId)
  };
  const handleClickEditTermsOfUse = () => {
    organisationSummaryActionCreators.startOrganisationMembersEdit(organisationId)
  };
  const handleClickAutoExtendIPRange = () => {
    organisationSummaryActionCreators.startOrganisationAutoExtendIPRange(organisationId)
  };
  const handleClickOrgDefaultCommunity = () => {
    organisationSummaryActionCreators.startOrganisationDefaultCommunity(organisationId)
  };
  const handleClickUserDefinedFieldsListing = () => {
    organisationSummaryActionCreators.startOrganisationUserDefinedFieldsListing(organisationId)
  };

  return (
    <ManagementToolsCommonComponent
      organisationId={organisationId}
      isExporting={isExporting}
      isProtected={isProtected()}
      tooltips={tooltips}
      handleClickViewUserList={handleClickViewUserList}
      handleClickExportUserList={handleClickExportUserList}
      handleClickExternalMembers={handleClickExternalMembers}
      handleClickReserveLicense={handleClickReserveLicense}
      handleClickPendingLicense={handleClickPendingLicense}
      handleClickBlockLicense={handleClickBlockLicense}
      handleClickEditTermsOfUse={handleClickEditTermsOfUse}
      handleClickAutoExtendIPRange={handleClickAutoExtendIPRange}
      handleClickOrgDefaultCommunity={handleClickOrgDefaultCommunity}
      handleClickUserDefinedFieldsListing={handleClickUserDefinedFieldsListing}
    />
  );
}

const mapStateToProps = state => ({
  isExporting: state.organisation.isExporting,
  organisation: state.organisation.organisation,
  membersCsv: state.organisation.membersCsv
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  organisationActionCreators: bindActionCreators(
    organisationActionCreators,
    dispatch
  ),
  organisationSummaryActionCreators: bindActionCreators(
    organisationSummaryActionCreators,
    dispatch
  )
});

export const ManagementTools = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagementToolsComponent);

export default ManagementTools;
