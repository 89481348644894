import * as React from 'react';
import { connect } from 'react-redux';
import { ActionButton } from '@fluentui/react/lib/Button';
import { BRANDNAME } from '../../common/Tooltips';

export class PendingComponent extends React.Component<any, any> {
  public render() {
    return (
      <div className='govTeams-account'>
        <div className='govTeams-row'>
          <div className=''>
            <h1>You're in the queue for a GovTEAMS Licence</h1>
            <hr />
            <p>
              We have notified your {BRANDNAME} organisation admins about your licence request.<br /><br />

              If you are at the Department of Defence, you will need to <a
                href={'https://dsms.dpe.protected.mil.au/saw/ess/smOfferingPage?id=GovTeamsLicenseRequest'}
                target={'_blank'}
                rel={'noreferrer'}
              >Log a job</a> to finalise your licence request.<br /><br />

              For all other agencies, please stand by while your {BRANDNAME} Admins process your request.<br /><br />
            </p>
            <p>
              For further information, contact your {BRANDNAME} organisation administrator.
            </p>
            <div className='spacing-bottom' />
            {this.props.profile &&
              this.props.profile.organisationContact && (
                <React.Fragment>
                  <h2 className='h3'>Your organisation contact</h2>
                  <ActionButton
                    className='meta-contactInfo'
                  href={`mailto:${this.props.profile.organisationContact}`}
                    iconProps={{ iconName: 'mail' }}
                  >
                  {this.props.profile.organisationContact}
                  </ActionButton>
                </React.Fragment>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  profile: state.profile
});

const mapDispatchToProps = (dispatch: any) => ({});

export const Pending = connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingComponent);

export default Pending;
