export const constants = {
  PROGRESS_ORG_FETCH: 'Fetching organisation',
  PROGRESS_ORG_FETCH_ALL: 'Fetching your organisations',
  PROGRESS_ORG_SAVE: 'Saving your organisation',
  PROGRESS_ORG_MEMBERS_FETCH: 'Fetching organisation members',
  PROGRESS_ORG_MEMBERS_CSV_FETCH: 'Preparing CSV for download',
  PROGRESS_ORG_LICENCE_ALLOCATION: 'Allocating member licences',
  PROGRESS_ORG_LICENCE_DEACTIVATION: 'Deactivating member licences',
  PROGRESS_ORG_LICENCE_REMOVAL: 'Removing member licences',
  PROGRESS_ORG_LICENCE_RESERVE: 'Reserving member licences',
  PROGRESS_ORG_LICENCE_BLOCK: 'Freezing member licences',
  PROGRESS_ORG_LICENCE_RESERVED_FETCH: 'Fetching reserved members',
  PROGRESS_ORG_LICENCE_BLOCKED_FETCH: 'Fetching blocked members',
  PROGRESS_ORG_EXT_MEMBERS_FETCH: 'Fetching external members',
  PROGRESS_ORG_IP_RANGE_FETCH: 'Fetching ip range',
  PROGRESS_ORG_EXT_MEMBER_REVIEW_FETCH: 'Fetching external member details',
  PROGRESS_ORG_EXT_MEMBER_REMOVE_FETCH: 'Removing external member',
  PROGRESS_ORG_UDF_MASTER_FETCH: 'Fetching user defined fields',
  PROGRESS_ORG_UDF_MASTER_SAVE: 'Saving your user defined field',
  PROGRESS_ORG_UDF_MASTER_DELETE: 'Deleting user defined field',
  PROGRESS_ORG_UDF_VALUE_BULK_SAVE: 'Saving users field values',
  PROGRESS_ORG_UDF_VALUE_FETCH: 'Fetching member user defined values',
  PROGRESS_ORG_UDF_VALUE_SAVE: 'Saving member user defined value',
  PROGRESS_ORG_UDF_VALUE_DELETE: 'Deleting member user defined value',
};

export default constants;
