/**
 * organisation management screen
 *
 */

/**
 * React
 */
import * as React from 'react';

/*
 * Redux
 */
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { bindActionCreators } from 'redux';

/**
 * Components
 */
import MemberUdfMasterListComponent from '@govteams/library/components/ManageOrganisation/MemberUdfMaster/ListComponent';
import GoBackComponent from '@govteams/library/components/ManageOrganisation/commons/GoBackComponent/';

/**
 * GovTEAMS
 */
import {
  constants as componentConstants,
  tooltips
} from '../OrganisationMembers/OrganisationMembers.constants';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';
import organisationActionCreators from '../../controllers/OrganisationController/Organisation.operations';

const OrganisationUdfMasterListComponent = (props) => {
  const {
    dispatch,
    match,
    organisation,
    error,
    udfMasterList,
    organisationActionCreators,
  } = props;

  const onGoBack = () => dispatch(replace(`${constants.SCREEN_ORGANISATION}/${match.params['organisationId']}`));

  return (
    <div className='orgMembersManagement-page'>
      <GoBackComponent
        linkLabel={constants.LINK_BACKTOORGMANAGEMENT}
        handleClick={onGoBack}
      />

      <MemberUdfMasterListComponent
        title={'Organisation user defined fields'}
        organisation={organisation}
        error={error}
        componentConstants={componentConstants}
        urlMatch={match}
        tooltips={tooltips}
        itemResult={udfMasterList}

        requestFetch={organisationActionCreators.requestFetchUdfMaster}
        requestAddSave={organisationActionCreators.requestAddUdfMaster}
        requestEditSave={organisationActionCreators.requestEditUdfMaster}
        requestDelete={organisationActionCreators.requestDeleteUdfMaster}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  organisation: state.organisation.organisation,
  udfMasterList: state.organisation.udfMasterList,
  error: state.organisation.error
});

const mapDispatchToProps = (dispatch) => ({
  organisationActionCreators: bindActionCreators(organisationActionCreators, dispatch),
  dispatch
});

export const OrganisationUdfMasterList = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationUdfMasterListComponent);

export default OrganisationUdfMasterList;