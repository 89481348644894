import { IDropdownOption } from '@fluentui/react/lib/Dropdown';

export const selectionActions = {
  allocateLicence: 'Allocate licence',
  deactivateLicence: 'Deactivate licence',
  moveToWaitlist: 'Move to waitlist',
  add: 'add',
  remove: 'remove',
  removeExternalMember: 'Remove external member',
  reviewExternalMember: 'Review external member'
};

export const constants = {
  ALERT_DIALOG_TITLE: 'Alert',
  ALERT_DIALOG_MESSAGE: `Oops! You've selected more than 10 users. You can only select up to 10 users at a time.`,
  MESSAGE_SELECTION_LIMIT:
    'Manage licences and users within your organisation. You can action up to 10 changes at a time.',
  MESSAGE_PREFILTER_PENDING: `Filter by 'pending licence' to see your organisation's waitlist. Users who requested a licence first will appear at the top of the list.`,
  RESULTS_DIALOG_TITLE: 'Success!',
  LINK_BACK: 'Back to organisation',
  EXTERNAL_MEMBERS_MESSAGE: 'Manage external users within your organisation',
  IP_RANGE_MESSAGE: 'GovTEAMS users are required to verify their account once every 60 days to confirm that they still work for the organisation listed on their account. The account auto-verification functionality determines if your users are signing in from a known organisation IP range and will automatically verify the user as signing in from the organisations IP address confirms that they still have access to your network. View the IP ranges configured for your organisation below. Contact GovTEAMS Support to make any changes.',
  UDF_MESSAGE: 'GovTEAMS org admins can create custom user defined fields and assign values to these custom fields for each member in their organisation.',
  UDF_VALUE_MESSAGE: 'Assign values to user defined fields to a member in the organisation.',
  ORGANISATION_DEFAULT_COMMUNITY_MESSAGE: 'The communities listed below are associated with your organization and will be automatically assigned to new or reactivated users. Contact GovTEAMS Support to make any changes.',
  UDF_MODAL_TITLE: 'Add Members\' Custom Fields',
  UDF_MODAL_BTN_SAVE: 'Save',
};

export const tooltips = {
  ALLOCATE_LICENCE: 'Allocate licence to member',
  DEACTIVATE_LICENCE: 'Deactivate the account and make the member inactive',
  MOVE_TO_WAITLIST:
    'Remove the licence and add the member to the organisation licence queue',
  FILTER_LIST: 'Apply email domain and licence status filters to member list',
  EXPORT_CSV: 'Export CSV of filtered member list',
  REVIEW_EXTERNAL: 'View the details of the selected external member',
  REMOVE_EXTERNAL: 'Remove the selected user from this organisation',
  ASSIGN_UDF_VALUE: 'Manage user defined field value',
};

/**
 * To do? : make into enum for use in other components?
 */
export const licenceStatusOptions: IDropdownOption[] = [
  {
    key: 'Active',
    text: 'Active'
  },
  {
    key: 'Under Review',
    text: 'Under Review'
  },
  {
    key: 'Inactive',
    text: 'Inactive'
  },

  {
    key: 'Blocked',
    text: 'Blocked'
  },
  {
    key: 'Pending Licence',
    text: 'Pending Licence'
  },

];
