/**
 * organisation management screen
 *
 */

/**
 * React
 */
import * as React from 'react';

/*
 * Redux
 */
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { bindActionCreators } from 'redux';

/**
 * Components
 */
import MemberUdfValueListComponent from '@govteams/library/components/ManageOrganisation/MemberUdfValue/ListComponent';
import GoBackComponent from '@govteams/library/components/ManageOrganisation/commons/GoBackComponent/';

/**
 * GovTEAMS
 */
import {
  constants as componentConstants,
  tooltips
} from '../OrganisationMembers/OrganisationMembers.constants';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';
import organisationActionCreators from '../../controllers/OrganisationController/Organisation.operations';
import {LINK_BACKTOORGUSERS} from "../../common/Constants";

const OrganisationUdfValueListComponent = (props) => {
  const {
    dispatch,
    match,
    organisation,
    error,
    udfMasterList,
    udfValueList,
    organisationActionCreators,
  } = props;

  const onGoBack = () => dispatch(replace(`${constants.SCREEN_ORGANISATION}/${match.params['organisationId']}/Members`));

  return (
    <div className='orgMembersManagement-page'>
      <GoBackComponent
        linkLabel={constants.LINK_BACKTOORGUSERS}
        handleClick={onGoBack}
      />

      <MemberUdfValueListComponent
        title={'Member values for user defined fields'}
        organisation={organisation}
        error={error}
        componentConstants={componentConstants}
        urlMatch={match}
        tooltips={tooltips}
        itemResult={udfValueList}
        udfMasterList={udfMasterList}

        actionCreators={organisationActionCreators}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  organisation: state.organisation.organisation,
  udfMasterList: state.organisation.udfMasterList,
  udfValueList: state.organisation.udfValueList,
  error: state.organisation.error
});

const mapDispatchToProps = (dispatch) => ({
  organisationActionCreators: bindActionCreators(organisationActionCreators, dispatch),
  dispatch
});

export const OrganisationUdfValueList = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationUdfValueListComponent);

export default OrganisationUdfValueList;