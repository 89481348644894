/**
 * organisation management screen
 *
 */

/**
 * React
 */
import React, {useEffect, useState} from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { organisationActionCreators } from '../../controllers/OrganisationController';

/**
 * Fabric UI
 */
import { ActionButton } from '@fluentui/react/lib/Button';

/**
 * Components
 */
import DashboardComponent from '@govteams/library/components/ManageOrganisation/DashboardComponent/';

import {
  IOrganisation,
  ILicence,
  IOrganisationMember
} from '../../controllers/OrganisationController';
import { ManagementTools } from './components/ManagementTools';
import { OrganisationAdministrators } from './components/OrganisationAdministrators';
import { OrganisationDomains } from './components/OrganisationDomains';
import { LicenceAllocationInfo } from './components/LicenceAllocationInfo';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';

const OrganisationSummaryComponent = (props) => {
  const organisation: IOrganisation = props.organisation;
  const domains: string[] = props.domains;
  const admins: IOrganisationMember[] = props.admins;
  const licence: ILicence = props.licence;
  const organisationId: string = props.organisationId !== undefined ? props.organisationId : props.match.params.organisationId;
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const init = async (organisationId: string)=> {
    await props.organisationActionCreators.requestFetchOrganisation(
      organisationId
    );

    await props.organisationActionCreators.requestFetchOrganisationAdmins(
      organisationId
    );

    await props.organisationActionCreators.requestFetchOrganisationDomains(
      organisationId
    );

    await props.organisationActionCreators.requestFetchOrganisationLicence(
      organisationId
    );

    setIsLoaded(true);
  }

  useEffect(() => {
    const _oId = props.organisationId !== undefined ? props.organisationId : props.match.params.organisationId
    if ( _oId ) {
      init(_oId).then(null);
    }
  }, [props.organisationId, props.match, organisationId]);

  const onClose = (event) => {
    props.dispatch(push(constants.SCREEN_DASHBOARD));
  };

  return (
    <div className='pageLayout-twoCol orgManagement-page'>
      <ActionButton
        className='govTeams-actionButton'
        onClick={onClose}
        iconProps={{ iconName: 'ChevronLeft' }}
      >
        {constants.LINK_BACKTODASHBOARD}
      </ActionButton>
      {organisation && !props.error ? (
        <DashboardComponent
          admins={admins}
          domains={domains}
          licence={licence}
          organisationId={organisationId}
          organisation={organisation}
          OrganisationDomains={<OrganisationDomains domains={domains} />}
          OrganisationAdministrators={<OrganisationAdministrators orgAdmins={admins} />}
          LicenceAllocationInfo={<LicenceAllocationInfo licence={licence} />}
          ManagementTools={<ManagementTools organisationId={organisationId} />}
        />
      ) : (
        <React.Fragment>
          {isLoaded && props.error && (
            <React.Fragment>
              <h1>Error</h1>
              <table className='table-requestError'>
                <tbody>
                <tr>
                  <th>Type</th>
                  <td>{props.error.code}</td>
                </tr>
                <tr>
                  <th>Message</th>
                  <td>{props.error.message}</td>
                </tr>
                <tr>
                  <th>CorrelationId</th>
                  <td>{props.error?.innerError?.requestId}</td>
                </tr>
                </tbody>
              </table>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};


const mapStateToProps = (state) => ({
  organisationId: state.organisationSummary.organisationId,
  organisation: state.organisation.organisation,
  admins: state.organisation.admins,
  domains: state.organisation.domains,
  licence: state.organisation.licence,
  error: state.organisation.organisationError
});

const mapDispatchToProps = (dispatch) => ({
  organisationActionCreators: bindActionCreators(
    organisationActionCreators,
    dispatch
  ),
  dispatch
});

export const OrganisationSummary = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationSummaryComponent);

export default OrganisationSummary;
