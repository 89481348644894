/**
 * Style dependencies
 */
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import './index.scss';
import 'es6-shim';
/**
 * React Redux
 */
import * as React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {Switch} from 'react-router';
import {AppInsightsErrorBoundary} from '@microsoft/applicationinsights-react-js';


import {history, store as _store} from './store/configureStore';
import AppRoute from './components/Route/AppRoute';
import AppSecureRoute from './components/Route/AppSecureRoute';

/**
 * Public app
 */
import PublicLayout from './public/Layout';
import ThankyouForRegistering from './public/ThankyouForRegistering';
import ConfirmationError from './public/Confirmation/ConfirmationError';
import ConfirmationSuccess from './public/Confirmation/ConfirmationSuccess';
import ConfirmationAlreadyActive from './public/Confirmation/ConfirmationAlreadyActive';
import ConfirmationVerificationSuccess from './public/Confirmation/ConfirmationVerificationSuccess';
import ConfirmationEmailAlreadyChanged from './public/Confirmation/ConfirmationEmailAlreadyChanged';
import Error from './public/Error/Error';
import UntrustedNetwork from './public/UntrustedNetwork';
import LoggingOut from './screens/Profile/LoggingOut';
import Logout from './screens/Profile/Logout';

/**
 * User app
 */
import UsersLayout from './screens/Layout';
import CommunityCreate from './screens/CommunityCreate/CommunityCreate';
import CommunityMembers from './screens/CommunityManagement/components/CommunityMembers';
import CommunityManage from './screens/CommunityManagement';
import Search from './screens/Search';
import MembersSearch from './screens/Search/components/Members';
import DocumentSearch from './screens/Search/components/Documents';
import ProfileLayout from './screens/Profile/Layout';
import Goodbye from './screens/Profile/Goodbye';
import Reactivate from './screens/Profile/Reactivate';
import Reactivated from './screens/Profile/Reactivated';
import ApprovalPending from './screens/Profile/ApprovalPending';
import Report_AllMembers from './screens/Reports/AllMembers';
import OrganisationSummary from './screens/OrganisationSummary';
import OrganisationLanding from './screens/OrganisationLanding';
import {OrganisationEdit} from './screens/OrganisationSummary/components/OrganisationEdit';
import OrganisationMembers from './screens/OrganisationMembers';
import {MembersLicencesReserve} from './screens/OrganisationMembers/components/MembersLicencesReserve';
import {MembersLicencesBlock} from './screens/OrganisationMembers/components/MembersLicencesBlock';
import Blocked from './screens/Blocked';
import Pending from './screens/Pending';
import ExternalMembers from './screens/ExternalMembers';
import OrganisationIPRange from './screens/OrganisationIPRange';
import OrganisationUdfMasterList from './screens/OrganisationUdfMaster/OrganisationUdfMasterList';
import OrganisationUdfValueList from './screens/OrganisationUdfValue/OrganisationUdfValueList';
import ExternalMemberReview from './screens/ExternalMemberReview';
import OrganisationDefaultCommunities from './screens/OrganisationDefaultCommunities';

/**
 * UI
 */
import {Dashboard} from './screens/Dashboard/Dashboard';
import {BotTermsConditions} from './screens/BotTnC/TermsConditions';

/**
 * Utils and constants
 */
import * as constants from './common/Constants';
import AzureInsights from './common/AzureInsights';

export const store = _store;

/**
 * Azure application insight installation begins here
 */
const azureInsights = AzureInsights.getInstance(history);
const reactPlugin = azureInsights.reactPlugin;

const PublicApp = () => {
  return (
    <AppInsightsErrorBoundary
      onError={Error}
      appInsights={reactPlugin}
    >
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            {/* Users */}
            <AppSecureRoute
              exact={true}
              path={constants.SCREEN_DASHBOARD}
              component={Dashboard}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={constants.SCREEN_PENDING}
              component={Pending}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={constants.SCREEN_BLOCKED}
              component={Blocked}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={`${constants.SCREEN_ORGANISATION}/:organisationId`}
              component={OrganisationSummary}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={`${constants.SCREEN_ORGANISATION}`}
              component={OrganisationLanding}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={`${constants.SCREEN_ORGANISATION}/:organisationId/Edit`}
              component={OrganisationEdit}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={`${constants.SCREEN_ORGANISATION}/:organisationId/Members`}
              component={OrganisationMembers}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={`${constants.SCREEN_ORGANISATION}/:organisationId/ExternalMembers`}
              component={ExternalMembers}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={`${constants.SCREEN_ORGANISATION}/:organisationId/IpRange`}
              component={OrganisationIPRange}
              layout={UsersLayout}
            />
            <AppSecureRoute
                exact={true}
                path={`${constants.SCREEN_ORGANISATION}/:organisationId/GetOrgDefaultCommunitiesList`}
                component={OrganisationDefaultCommunities}
                layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={`${constants.SCREEN_ORGANISATION}/:organisationId/UserDefinedFields/MasterList`}
              component={OrganisationUdfMasterList}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={`${constants.SCREEN_ORGANISATION}/:organisationId/UserDefinedFields/Members/:memberAzureId/udfValues`}
              component={OrganisationUdfValueList}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={`${constants.SCREEN_ORGANISATION}/:organisationId/ExternalMembers/:memberAzureId/Review`}
              component={ExternalMemberReview}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={`${constants.SCREEN_ORGANISATION}/:organisationId/Members/:licenceStatus`}
              component={OrganisationMembers}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={`${constants.SCREEN_ORGANISATION}/:organisationId/Members/Licences/Reserved`}
              component={MembersLicencesReserve}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={`${constants.SCREEN_ORGANISATION}/:organisationId/Members/Licences/Blocked`}
              component={MembersLicencesBlock}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={constants.SCREEN_COMMUNITY}
              component={CommunityCreate}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={`${constants.SCREEN_COMMUNITY}/:communityAzureId`}
              component={CommunityManage}
              layout={UsersLayout}
            />
            <AppSecureRoute
              path={`${constants.SCREEN_SEARCH}/:filter`}
              component={Search}
              layout={UsersLayout}
            />
            <AppSecureRoute
              path={constants.SCREEN_SEARCH}
              component={Search}
              layout={UsersLayout}
            />
            <AppSecureRoute
              path={`${constants.SCREEN_COMMUNITY}/:communityAzureId/Members`}
              component={CommunityMembers}
              layout={UsersLayout}
            />
            <AppSecureRoute
              path={constants.SCREEN_SEARCHMEMBERS}
              component={MembersSearch}
              layout={UsersLayout}
            />
            <AppSecureRoute
              path={constants.SCREEN_SEARCHDOCUMENTS}
              component={DocumentSearch}
              layout={UsersLayout}
            />
            <AppSecureRoute
              path={constants.SCREEN_BOTTERMSCONDITIONS}
              component={BotTermsConditions}
              layout={UsersLayout}
            />
            <AppSecureRoute
              path={constants.SCREEN_ADMIN_ALLMEMBERS}
              component={Report_AllMembers}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={constants.SCREEN_PROFILE}
              component={ProfileLayout}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={constants.SCREEN_APPROVAL_PENDING}
              component={ApprovalPending}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={constants.SCREEN_REACTIVATE}
              component={Reactivate}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={constants.SCREEN_VERIFIED}
              component={Reactivated}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={constants.SCREEN_VERIFIED_EMAIL_CHANGE}
              component={Reactivated}
              layout={UsersLayout}
            />
            <AppSecureRoute
              exact={true}
              path={constants.SCREEN_VERIFY}
              component={Reactivate}
              layout={UsersLayout}
            />

            {/* Public */}
            <AppRoute
              exact={true}
              path={constants.SCREEN_LOGGING_OUT}
              component={LoggingOut}
              layout={PublicLayout}
            />
            <AppRoute
              exact={true}
              path={constants.SCREEN_LOGOUT}
              component={Logout}
              layout={PublicLayout}
            />
            <AppRoute
              exact={true}
              path={constants.SCREEN_GOODBYE}
              component={Goodbye}
              layout={PublicLayout}
            />
            <AppRoute
              exact={true}
              path={constants.SCREEN_UNTRUSTED_NETWORK}
              component={UntrustedNetwork}
              layout={PublicLayout}
            />
            <AppRoute
              exact={true}
              path={constants.SCREEN_CONFIRMATIONERROR}
              component={ConfirmationError}
              layout={PublicLayout}
            />
            <AppRoute
              exact={true}
              path={constants.SCREEN_CONFIRMATIONSUCCESS}
              component={ConfirmationSuccess}
              layout={PublicLayout}
            />
            <AppRoute
              exact={true}
              path={constants.SCREEN_CONFIRMATIONALREADYACTIVE}
              component={ConfirmationAlreadyActive}
              layout={PublicLayout}
            />
            <AppRoute
              exact={true}
              path={constants.SCREEN_CONFIRMATIONVERIFICATIONSUCCESS}
              component={ConfirmationVerificationSuccess}
              layout={PublicLayout}
            />
            <AppRoute
              exact={true}
              path={constants.SCREEN_CONFIRMATIONEMAILCHANGED}
              component={ConfirmationEmailAlreadyChanged}
              layout={PublicLayout}
            />
            <AppRoute
              exact={true}
              path={constants.SCREEN_THANKYOU}
              component={ThankyouForRegistering}
              layout={PublicLayout}
            />
            <AppRoute
              exact={true}
              path={constants.SCREEN_ERROR}
              component={Error}
              layout={PublicLayout}
            />

            {/* Catch All */}
            <AppSecureRoute
              path={constants.SCREEN_DASHBOARD}
              component={Dashboard}
              layout={UsersLayout}
            />
          </Switch>
        </ConnectedRouter>
      </Provider>
    </AppInsightsErrorBoundary>
  );
}

export default PublicApp;
