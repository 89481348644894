/**
 * organisation management screen
 *
 */

/**
 * React
 */
import * as React from 'react';

/*
 * Redux
 */
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { bindActionCreators } from 'redux';

/**
 * Components
 */
import IPRangeCommonComponent from '@govteams/library/components/ManageOrganisation/IPRangeComponent/';
import GoBackComponent from '@govteams/library/components/ManageOrganisation/commons/GoBackComponent/';

/**
 * GovTEAMS
 */
import {
  constants as componentConstants,
  tooltips
} from '../OrganisationMembers/OrganisationMembers.constants';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';
import organisationActionCreators from '../../controllers/OrganisationController/Organisation.operations';

const OrganisationIPRangeListComponent = (props) => {
  const { dispatch, match, organisation, error, ipRangeResult, ipRangeMessage, ipRangeError, organisationActionCreators } = props;

  const onGoBack = () => dispatch(replace(`${constants.SCREEN_ORGANISATION}/${match.params['organisationId']}`));

  return (
    <div className='orgMembersManagement-page'>
      <GoBackComponent
        linkLabel={constants.LINK_BACKTOORGMANAGEMENT}
        handleClick={onGoBack}
      />

      <IPRangeCommonComponent
        title={'Account auto-verification IP ranges'}
        organisation={organisation}
        error={error || ipRangeError}
        componentConstants={componentConstants}
        urlMatch={match}
        tooltips={tooltips}
        callbackOnLoad={organisation}
        itemResult={ipRangeResult}
        apiMessage={ipRangeMessage}

        requestFetch={organisationActionCreators.requestFetchIPRange}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  organisation: state.organisation.organisation,
  ipRangeResult: state.organisation.ipRangeResult,
  ipRangeError: state.organisation.ipRangeError,
  ipRangeMessage: state.organisation.ipRangeMessage,
  error: state.organisation.error
});

const mapDispatchToProps = (dispatch) => ({
  organisationActionCreators: bindActionCreators(
    organisationActionCreators,
    dispatch
  ),
  dispatch
});

export const OrganisationIPRangeList = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationIPRangeListComponent);

export default OrganisationIPRangeList;