import { replace } from 'react-router-redux';
import { actions } from './OrganisationSummary.actions';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';

export const organisationSummaryActionCreators = {
  startOrganisationManage: (organisationId: string) => (dispatch, getState) => {
    dispatch({ type: actions.ORG_MANAGE_START, organisationId });
  },
  startOrganisationMembersManage: (organisationId: string) => (
    dispatch,
    getState
  ) => {
    dispatch({ type: actions.ORG_MANAGE_MEMBERS_START, organisationId });
    dispatch(
      replace(`${constants.SCREEN_ORGANISATION}/${organisationId}/Members`)
    );
  },
  startOrganisationMembersEdit: (organisationId: string) => (
    dispatch,
    getState
  ) => {
    dispatch({ type: actions.ORG_MANAGE_MEMBERS_START, organisationId });
    dispatch(
      replace(`${constants.SCREEN_ORGANISATION}/${organisationId}/Edit`)
    );
  },
  endOrganisationMembersManage: (organisationId: string) => (
    dispatch,
    getState
  ) => {
    dispatch({ type: actions.ORG_MANAGE_MEMBERS_END, organisationId });
    dispatch(replace(`${constants.SCREEN_ORGANISATION}/${organisationId}`));
  },
  startOrganisationMembersPendingManage: (organisationId: string) => (
    dispatch,
    getState
  ) => {
    dispatch({
      type: actions.ORG_MANAGE_MEMBERS_PENDING_START,
      organisationId
    });
    dispatch(
      replace(
        `${constants.SCREEN_ORGANISATION}/${organisationId}/Members/Pending Licence`
      )
    );
  },
  startOrganisationMembersReserveLicences: (organisationId: string) => (
    dispatch,
    getState
  ) => {
    dispatch({ type: actions.ORG_RESERVE_LICENCES_START, organisationId });
    dispatch(
      replace(
        `${constants.SCREEN_ORGANISATION}/${organisationId}/Members/Licences/Reserved`
      )
    );
  },
  startOrganisationMembersBlockLicences: (organisationId: string) => (
    dispatch,
    getState
  ) => {
    dispatch({ type: actions.ORG_FREEZE_LICENCES_START, organisationId });
    dispatch(
      replace(
        `${constants.SCREEN_ORGANISATION}/${organisationId}/Members/Licences/Blocked`
      )
    );
  },
  
  startExternalMembersManage: (organisationId: string) => (
    dispatch    
  ) => {
    dispatch({ type: actions.ORG_MANAGE_EXTERNAL_MEMBERS_START, organisationId });
    dispatch(
      replace(`${constants.SCREEN_ORGANISATION}/${organisationId}/ExternalMembers`)
    );
  },

  startOrganisationAutoExtendIPRange: (organisationId: string) => (
    dispatch
  ) => {
    dispatch({ type: actions.ORG_MANAGE_AUTO_EXTEND_ID_RANGE_START, organisationId });
    dispatch(
      replace(`${constants.SCREEN_ORGANISATION}/${organisationId}/IpRange`)
    );
  },

  startOrganisationDefaultCommunity: (organisationId: string) => (
      dispatch
  ) => {
        dispatch({ type: actions.ORG_MANAGE_START, organisationId });
        dispatch(
            replace(`${constants.SCREEN_ORGANISATION}/${organisationId}/GetOrgDefaultCommunitiesList`)
        );
  },

  startOrganisationUserDefinedFieldsListing:  (organisationId: string) => (
    dispatch
  ) => {
    dispatch({ type: actions.ORG_MANAGE_UDF_START, organisationId });
    dispatch(
      replace(`${constants.SCREEN_ORGANISATION}/${organisationId}/UserDefinedFields/MasterList`)
    );
  },

  // endExternalMembersManage: (organisationId: string) => (
  //   dispatch
  // ) => {
  //   dispatch({ type: actions.ORG_MANAGE_EXTERNAL_MEMBERS_END, organisationId });
  //   dispatch(replace(`${constants.SCREEN_ORGANISATION}/${organisationId}`));
  // }
};

export default organisationSummaryActionCreators;
