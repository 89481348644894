import { BRANDNAME } from '../../common/Tooltips';

export const tooltips = {
  VIEW_MEMBERS: 'View and manage the list of members in your organisation',
  RESERVE_LICENCES:
    `Reserve a ${BRANDNAME} licence for a user inside your organisation`,
  FREEZE_LICENCES: 'Prevent a user from being allocated a licence',
  EXPORT_CSV: 'Export a list of members in your organisation',
  VIEW_PENDING_LICENCES: 'View a list of users that have requested a licence',
  EDIT_TOU: "Tell your users more about your organisation's terms and conditions",
  VIEW_EXTERNAL_MEMBERS: 'View and manage the list of external members that your organisation is responsible for',
  VIEW_IP_RANGE: 'View IP Ranges',
  VIEW_ORG_DEFAULT_COMMUNITY: 'View the default communities that will be automatically assigned to new and reactivated users.',
  VIEW_USER_DEFINED_CUSTOM_FIELDS: 'View the list of user defined custom fields that can be assigned to users.',
};
